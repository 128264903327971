<template>
  <div class="pa-3">
    <div class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <v-btn
          class="mr-6"
          color="success"
          v-if="hasAdditionBtn"
          @click="openRestaurantDialog({})"
        >
          <v-icon class="mr-2">mdi-plus</v-icon>
          Добавить
        </v-btn>
      </div>
      <div>
        <v-text-field
          v-model="search"
          label="Поиск"
          color="green"
          class="justify-end"
          append-icon="mdi-magnify"
          single-line
          v-if="hasSearchTextField"
        />
      </div>
    </div>
    <div>
      <DataTableComponent
        :headers="modiHeaders"
        :List="items"
        :ValueList="[5, 10, 15, {text: 'Все', value: -1}]"
        :itemPageProp="5"
        :search="search"
        @eventProp="rowClick"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            @click.stop="
              delete_dialog = true;
              value = item;
            "
          >
            mdi-delete
          </v-icon>
        </template>
      </DataTableComponent>
      <!-- <v-data-table
        :headers="modiHeaders"
        :items="items"
        item-key="id"
        :items-per-page="5"
        :search="search"
        @click:row="rowClick"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            @click.stop="
              delete_dialog = true;
              value = item;
            "
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table> -->
      <v-dialog v-model="delete_dialog" width="400px">
        <v-card>
          <v-card-title> Подтверждение удаления данных </v-card-title>
          <v-card-text> Вы уверены, что хотите удалить запись? </v-card-text>
          <v-card-actions>
            <v-btn
              width="150px"
              color="success"
              outlined
              text
              @click="
                deleteRestaurant(value);
                delete_dialog = false;
              "
            >
              Да
            </v-btn>
            <v-spacer />
            <v-btn
              @click="delete_dialog = false"
              text
              width="150px"
              color="red"
              dark
              outlined
            >
              Нет
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import DataTableComponent from "./DataTableComponent.vue";
export default {
  name: "RefNavigator",
  props: [
    "headers",
    "items",
    "hasAdditionBtn",
    "hasShowRemovedBtn",
    "hasSearchTextField",
  ],
  components: {
    DataTableComponent
  },
  data: () => ({
    search: "",
    delete_dialog: false,
    value: {}
  }),

  methods: {
    ...mapActions(["openRestaurantDialog", "deleteRestaurant"]),
    rowClick(item) {
      this.openRestaurantDialog(item);
    },
    jopa(item) {
      let temp = item;
      temp = "";
      console.log(temp);
    },
  },
  computed: {
    modiHeaders() {
      return [
        ...this.headers,
        {
          text: "Действия",
          value: "actions",
        },
      ];
    },
  },
};
</script>