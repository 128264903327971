<template>
  <div class="text-center">
    <v-dialog v-model="showInfoDialog" width="500">
      <v-card height="200px">
        <v-toolbar
          :color="
            connectionStatus && connectionStatus != 'InvalidRestTokenException'
              ? 'green'
              : 'red'
          "
        >
          <v-toolbar-title class="white--text"
            >Статус подключения</v-toolbar-title
          >
        </v-toolbar>

        <v-card-text class="text-h5 pa-6">{{ messageText }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="openInfoDialog()"> ОК </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["showInfoDialog", "messageText", "connectionStatus"]),
  },
  methods: {
    ...mapActions(["openInfoDialog"]),
  },
};
</script>

<style>
</style>