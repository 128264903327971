<template>
    <div>
        <div style="display: flex; justify-content: flex-end; align-items: baseline;">
            <span class="mb-4 mr-2">Строк на странице</span>
            <v-autocomplete
                class="mb-4 mr-2"
                :items="ValueList"
                v-model="itemPage"
                dense
                style="max-width: 90px; width: 10px; text-align: left;"
            ></v-autocomplete>
            <v-pagination
                v-model="page"
                :length="pageCount" 
                total-visible="7"
                color="#4CAF50"
            ></v-pagination>
        </div>
        <v-data-table
            :no-data-text="'Данные отсутствуют'"
            dense
            no-results-text="Результатов нет"
            :hide-default-footer="true"
            :items-per-page="itemPage"
            :items="List"
            :headers="headers"
            :search="search"
            :page.sync="page"
            @click:row="event"
            @page-count="pageCount = $event"
        >
            <template v-for="(_, name) in $scopedSlots" #[name]="data">
				<slot :name="name" v-bind="data"/>
			</template>
        </v-data-table>
        <div style="display: flex; justify-content: flex-end;">
            <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="7"
                color="#4CAF50"
            ></v-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DataTableComponent',
        
        props: {
            headers: {
                type: Array,
                required: true
            },
            List: {
                type: Array,
                required: true
            },
            ValueList: {
                type: Array,
                required: true
            },
            itemPageProp: {
                type: Number,
                required: true
            },
            search: {
                type: String,
                required: false
            },
            eventProp: {
                type: Function,
                required: false
            }
        },

        data() {
            return {
                page: 1,
                itemPage: 10,
                pageCount: undefined
            }
        },

        created() {
            this.itemPage = this.itemPageProp;
        },

        methods: {
            event(object) {
                this.$emit('eventProp', object);
            }
        }
    }    
</script>

<style scoped>

</style>