<template>
  <v-sheet outlined rounded min-height="100%">
    <!-- {{ companies }} -->
    <Loader v-if="loading" />
    <RefNavigator
      v-else
      :items="restaurants.filter((x) => x.deleted == false)"
      :headers="headers"
      :hasAdditionBtn="true"
      :hasSearchTextField="true"
      :hasShowRemovedBtn="true"
    />
    <restaurant-settings-dialog></restaurant-settings-dialog>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RefNavigator from "../components/RefNavigator.vue";
import RestaurantSettingsDialog from "../components/RestaurantSettingsDialog.vue";
import Loader from "../components/ui/Loader.vue";

export default {
  name: "Companies",
  components: {
    RefNavigator,
    Loader,
    RestaurantSettingsDialog,
  },
  data() {
    return {
      loading: true,
    };
  },
  async mounted() {
    this.loading = false;
    await this.load_restaurants();
  },
  methods: {
    ...mapActions(["load_restaurants"]),
  },
  computed: {
    ...mapGetters(["restaurants"]),
    headers() {
      return [
        {
          text: "#",
          align: "start",
          value: "id",
        },
        {
          text: "Адрес",
          value: "address",
        },
        {
          text: "Название",
          value: "title",
        },
        {
          text: "Удалённый хост",
          value: "remote_host",
        },
        {
          text: "Токен",
          value: "access_token",
        },
      ];
    },
  },
};
</script>