<template>
  <v-row justify="center">
    <v-dialog v-model="showRestaurantDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Информация о ресторане</span>
        </v-card-title>
        <div
          v-if="loading"
          class="d-flex justify-center align-center fill-height"
        >
          <Loader v-if="loading" />
        </div>
        <v-card-text v-else>
          <v-form v-model="valid">
            <v-container>
              <v-row class="d-flex justify-center">
                <v-col cols="12">
                  <v-text-field
                    :value="request.address"
                    @input="update('address', $event)"
                    label="Адрес ресторана"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :value="request.remote_host"
                    @input="update('remote_host', $event)"
                    :rules="[(v) => !!v || 'Введите адрес хоста ресторана']"
                    label="Удалённый хост"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :value="request.title"
                    @input="update('title', $event)"
                    label="Название ресторана"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :value="request.access_token"
                    @input="update('access_token', $event)"
                    :rules="[(v) => !!v || 'Введите токен ресторана', (v) => !v.includes(' ') || 'Токен не должен содержать в себе пробелов']"
                    label="Токен ресторана"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="green"
            @click="checkConnection()"
            :disabled="!valid"
            >Check Connection</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="red"
            @click="
              openRestaurantDialog({});
              $store.commit('set_restaurant_status', false);
              request_obj = {};
            "
          >
            Close
          </v-btn>
          <v-btn
            text
            color="green"
            :disabled="!valid"
            @click="
              updateRestaurant(request);
              openRestaurantDialog({});
            "
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
      <info-dialog></info-dialog>
    </v-dialog>
  </v-row>
</template>

<script>
import Loader from "@/components/ui/Loader.vue";
import { mapActions, mapGetters } from "vuex";
import InfoDialog from "./ui/InfoDialog.vue";
export default {
  name: "restaurant-settings-dialog",
  components: { Loader, InfoDialog },
  data() {
    return {
      loading: false,
      valid: false,
      request_obj: {},
    };
  },

  computed: {
    ...mapGetters([
      "showRestaurantDialog",
      "currentRestaurant",
      "connectionStatus",
    ]),
    request: {
      get: function () {
        return { ...this.currentRestaurant, ...this.request_obj };
      },
      set: function (newValue) {
        this.request_obj = { ...this.request_obj, ...newValue };
      },
    },
  },
  methods: {
    ...mapActions([
      "openRestaurantDialog",
      "checkRestaurantConnection",
      "openInfoDialog",
      "updateRestaurant",
    ]),
    update(key, value) {
      var tempObj = {};
      tempObj[key] = value;
      this.request = tempObj;
    },
    async checkConnection() {
      this.loading = true;
      await this.checkRestaurantConnection(this.request);
      this.loading = false;
      this.openInfoDialog(this.connectionStatus);
    },
  },
};
</script>